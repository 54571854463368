<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row >
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row class="justify-content-center">

              <!-- BEGIN::Upload File Field -->
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.uploadFile')"
                  label-for="upload_file"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="upload_file"
                    rules="required"
                  >
                    <b-form-file
                      id="upload_file"
                      v-model="form.upload_file"
                      accept=".xlsx, .xls"
                      drop-placeholder="Drop file here..."
                      :state="errors.length > 0 || fileState ? false:null"
                      @input="validateFile"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- END::Upload File Field -->

              <!-- BEGIN::Office Field -->
              <b-col md="8" v-if="companyActive.plan_id > 2">
                <b-form-group :label="$t('globalSingular.office')" label-for="office_id">
                  <validation-provider
                    #default="{ errors }"
                    name="office_id"
                  >
                    <v-select
                      id="office_id"
                      v-model="form.office_id"
                      :options="LOV.offices"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      class="select-size-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- END::Office Field -->

              <!-- BEGIN::Date Field -->
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.date')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- END::Date Field -->

              <!-- BEGIN::Tag Field -->
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.tags')"
                  label-for="tags"
                >
                  <v-select
                    class="select-size-sm"
                    v-model="form.tags"
                    id="tags"
                    label="name"
                    multiple
                    :options="LOV.tags"
                    :reduce="field => field.id"
                    :searchable="true"
                  />
                </b-form-group>
              </b-col>
              <!-- END::Tag Field -->

              <!-- BEGIN::Description Field -->
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t('apps.transactionsDraftJournal.singular.name')"
                  label-for="filename"
                >
                  <b-form-input
                    size="sm"
                    id="filename"
                    v-model="form.remark"
                    :placeholder="$t('apps.transactionsDraftJournal.placeholder.filename')"
                  />
                </b-form-group>
              </b-col>
              <!-- END::Description Field -->

            </b-row>

            <div v-if="loading">
              <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle" />
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
              </div>
            </div>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                variant="outline-secondary"
                :to="{ name: 'apps-transactions-draft-journal-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ $t('globalActions.create') }}
              </b-button>

            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>


<script>

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BForm,
  BFormInput,
  BButton,
  BSpinner
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatDatepicker } from '@/utils/formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useHttp from '@/comp-functions/useHttp'
import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
// import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BForm,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BSpinner
  },


  setup () {
    const { toast, $put } = useHttp()
    // const { $t } = useLocalization()
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      show,
      store,
      destroy
    } = useFormResource({
      url: 'transaction/draft-journal/upload',
      localeContextPath: 'apps.transactionsDraftJournal.singular.transaction',
      redirectPathName: 'apps-transactions-draft-journal'
    })

    const LOV = ref({
      tags: [],
      offices: []
    })

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    const initForm = {
      date: formatDatepicker(Date.now()),
      remark: '',
      tags: [],
      office_id: null,
      upload_file: null,
    }
    const form = ref({
      date: initForm.date,
      remark: initForm.remark,
      tags: initForm.tags,
      office_id: initForm.office_id,
      upload_file: initForm.upload_file,
    })

    const fileState  = ref(null)
    const loading    = ref(false)

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year, companyActive.closing_month, 1).getTime()))
        }
      ]
    })


    onMounted(() => {
      getTags()
      if (companyActive.plan_id > 2) {
        getOffice()
      }
    })


    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    const resetForm = () => form.value = initForm

    return {
      companyActive,
      pushTags,
      required,
      LOV,
      form,
      dateFormat,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      toast,
      $put,
      show,
      store,
      destroy,
      loading,
      fileState,
      resetForm
    }
  },


  methods: {
    validateFile() {
      const allowedExtensions = /\.(xlsx|xls)$/i
      const fileName = this.form.upload_file ? this.form.upload_file.name : ''
      if (!allowedExtensions.exec(fileName)) {
        this.fileState = false
        this.toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Invalid File Type',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: `Please upload a valid .xlsx / .xls file..`
          }
        })
      } else {
        this.fileState = true
        this.setFileNameToRemark(fileName)
      }
    },


    setFileNameToRemark(fileName) {
      const noExt = fileName.replace('.xlsx', '').replace('.xls', '')
      this.form.remark = noExt
    },


    async handleSubmit () {
      this.loading = true
      const forms = new FormData()
      await Promise.all(Object.keys(this.form).map(key => {
        const value = this.form[key]

        if (Array.isArray(value)) {
          return this.form[key].map((item, index) => 
            forms.append(`${key}[${index}]`, item))
        }

        return forms.append(key, value)
      }))

      let referenceCode
      await this.store({
        $swal: this.$swal,
        data: forms,
        useRedirect: false
      })
        .then(async (result) => {
          if (result.data.failed) {
            const failedCount = result.data.failed
            const successCount = result.data.success
            referenceCode = result.data.code

            return await this.$swal({
              title: 'Partially Success Uploaded!',
              // text: `There are ${failedCount} rows of data that failed to upload, do you want to continue saving the other ${successCount} rows that were successfully uploaded?`,
              html: `There are <span class="text-danger">${failedCount} rows</span> of data that <span class="text-danger">failed</span> to upload, do you want to continue saving the other <span class="text-success">${successCount} rows</span> that were <span class="text-success">successfully uploaded</span>?`,
              icon: 'question',
              showCancelButton: true,
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-flat-warning'
              }
            })
              .then(({ isDismissed, isDenied }) =>
                isDismissed || isDenied ? this.rollbackUploadedDraft(referenceCode) : null)
          } else {
            return this.$router.replace({ name: `apps-transactions-draft-journal-list` })
          }
        })
          .catch((error) => { console.log(error.response || error) })
          .finally(() => this.loading = false)
    },

    async rollbackUploadedDraft (referenceCode) {
      this.loading = true
      const url = `transaction/draft-journal/upload?reference_code=${referenceCode}`
      await this.destroy(this.$swal, url, null, { useRedirect: false })
        .then(() => { this.resetForm(); this.$refs.formRef.reset() })
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
